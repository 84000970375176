import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { WaterLeakageDevice } from "../components/WaterLeakageDevice.jsx";
import React, { useState, useContext, useEffect } from "react";
import { SignalRContext } from "./SignalRContext";

export const WaterLeaksPage = () => {
  const { message } = useContext(SignalRContext);
  const [devices, setDevices] = useState([]);

  const location = useLocation();

  let deviceList = [];
  let waterLeaks = [];

  if (message.flag === false) {
    waterLeaks = location.state;
  } else {
    deviceList = devices;
    waterLeaks = deviceList.filter((e) => {
      if (e.deviceType === 4 && e.status === "1") {
        return true;
      } else {
        return false;
      }
    });
  }

  useEffect(() => {
    // Trigger the loader to fetch data
    if (message.flag === true) {
      //     console.log("flag = " + message.flag);
      setDevices(message.devices);
    }
    //   console.log("SignalR message received in Child2:", message);
    // Fetch new data or update the content here
  }, [message]);

  return (
    <Box mt="20px" ml="20px" mr="20px">
      <Text mb="20px" ml="5px" fontSize="sm"></Text>
      <SimpleGrid columns={[1, 1, 2, 2, 3, 3, 4]} spacing="10px">
        {waterLeaks.map((e) => (
          <WaterLeakageDevice key={e.deviceId} device={e} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
