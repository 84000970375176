import React, { useEffect, useState, createContext } from "react";
import * as signalR from "@microsoft/signalr";

const SignalRContext = createContext();

const SignalRProvider = ({ children }) => {
  const [message, setMessage] = useState({ devices: {}, flag: false });
  const url = process.env.REACT_APP_API_SERVER_URL + "/deviceHub";
  console.log("Signal URL = " + url);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => {
        console.log("Connected to SignalR hub");
        connection.on("ReceiveDeviceData", (obj) => {
          // console.log("log message " + obj);
          const time = Date.now();
          console.log("log time " + time);
          setMessage({ devices: obj, flag: true });
        });
      })
      .catch((error) => console.error("Connection failed: ", error));

    return () => {
      connection
        .stop()
        .then(() => console.log("Disconnected from SignalR hub"));
    };
  }, []);

  return (
    <SignalRContext.Provider value={{ message }}>
      {children}
    </SignalRContext.Provider>
  );
};

export { SignalRContext, SignalRProvider };
