import React from 'react'
import { Box,Text} from '@chakra-ui/react'
import wmIcon from '../images/sensor2.png'
import { useTranslation } from 'react-i18next';


export const  Device=({device}) => {
  const {t}=useTranslation();

  return (
    <Box w='150px' minW='150px' borderWidth='1px' borderRadius='lg' overflow='hidden'>
      <Box m='5px'>
        <Text fontSize='xs'>{device.modelName}</Text>
        <Box display='flex' alignItems='center' pl='20px' pt='10px' pb='10px'>
            <img width={45} height={45} src={wmIcon}/>
            <Text fontSize='sm' m='10px'>{device.status=== '1' ? t('text_active'):t('text_idle')}</Text>
        </Box>
      </Box>
    </Box>
  )
}