import React from "react";
import { Box, Text } from "@chakra-ui/react";
import wmIcon from "../images/sensor2.png";
import { useTranslation } from "react-i18next";

export const AirQualityDevice = ({ device }) => {
  const { t } = useTranslation();
  //        <Text fontSize='xl' m='10px'>{device.status === '1' ? t('text_poor'):t('text_normal')} </Text>

 /*return (
    <Box mb="40px">
      <Text fontSize="sm" m="2px">
        {device.floor} 樓 {device.location}{" "}
      </Text>
      <Box minW="300px" borderWidth="1px" borderRadius="lg" overflow="hidden">
        <Box m="5px" display="flex" alignItems="center">
          <img width={14} height={14} src={wmIcon} />
          <Text ml="4px" fontSize="lg">
            {t("text_air_quality")}
          </Text>
        </Box>
        <Box mt="10px" mb="30px" align="center">
          {device.status === "1" ? (
            <Text fontSize="xl" m="10px" color="red.500">
              {t("text_poor")}
            </Text>
          ) : (
            <Text fontSize="xl" m="10px" color="green.500">
              {t("text_normal")}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );*/

    return (
       <Box mb="40px">
         <Text fontSize="sm" m="2px">
           {device.floor} 樓 {device.location}{" "}
         </Text>
         <Box minW="300px" borderWidth="1px" borderRadius="lg" overflow="hidden">
           <Box m="5px" display="flex" alignItems="center">
             <img width={14} height={14} src={wmIcon} />
             <Text ml="4px" fontSize="lg">
               {t("text_air_quality")}
             </Text>
           </Box>
           <Box mt="10px" mb="30px" align="center">
               <Text fontSize="xl" m="10px" color="green.500">
                 {device.status}
               </Text>
           </Box>
         </Box>
       </Box>
     );
};
