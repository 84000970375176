import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          text_indoor_air_quality: "Air Quality",
          text_normal: "Normal",
          text_disabled_toilet: "Disabled Toilet",
          text_roof_exit: "Roof Exit",
          text_25F_motion: "Motion on 25th Floor",
          text_water_leakages: "Water Leakage(s)",
          text_washinig_machines_in_use: "Washing Machine(s) In-Use",
          text_dryers_in_use: "Dryer(s) In-Use",
          text_select_floor: "Select floor",
          text_not_found: "Not Found",
          text_plk_youth_oasis: "Po Leung Kuk Lee Shau Kee Youth Oasis",
          text_floor: "Floor",
          text_floor1: "Floor",
          text_in_use: "In-Use",
          text_idle: "Idle",
          text_poor: "Poor",
          text_logout: "Logout",
          text_dashboard: "Dashboard",
          text_floors: "Floors",
          text_menu: "Menu",
          text_active: "Active",
          text_fall_detected: "Fall Detected",
          text_motion_detected: "Motion Detected",
          text_leak_detected: "Leak Detected",
          text_water_leakage: "Water Leakage",
          text_washing_machine: "Washing Machine",
          text_air_quality: "Air Quality",
          text_exit_detection: "Exit",
          text_dryer: "Dryer",
          text_fall_detection: "Fall Detection",
          text_error: "Sorry, an unexpected error has occurred.",
        },
      },
      zh_HK: {
        translation: {
          text_indoor_air_quality: "室內空氣質素",
          text_normal: "正常",
          text_disabled_toilet: "傷殘洗手間",
          text_roof_exit: "天台出口",
          text_25F_motion: "25樓在場檢測",
          text_water_leakages: "漏水情況",
          text_washinig_machines_in_use: "使用中 - 洗衣機",
          text_dryers_in_use: "使用中 - 乾衣機",
          text_select_floor: "選擇樓層",
          text_not_found: "Not Found",
          text_plk_youth_oasis: "保良局李兆基青年綠洲",
          text_floor: "樓層",
          text_floor1: "樓",
          text_in_use: "使用中",
          text_idle: "可使用",
          text_poor: "欠佳",
          text_logout: "登出",
          text_dashboard: "訊息中心",
          text_floors: "樓層",
          text_menu: "菜單",
          text_active: "使用中",
          text_fall_detected: "有人跌到",
          text_motion_detected: "有人",
          text_leak_detected: "漏水",
          text_water_leakage: "漏水檢測",
          text_washing_machine: "洗衣機",
          text_air_quality: "室內空氣質素",
          text_exit_detection: "通道檢測",
          text_dryer: "乾衣機",
          text_fall_detection: "跌倒檢測",
          text_error: "抱歉，發生意外錯誤",
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });
i18n.changeLanguage("zh_HK");
export default i18n;
