import React from "react";
import { NavBar2 } from "../components/navigation/desktop/NavBar2";
import { Outlet } from "react-router-dom";
import { SidenavProvider } from "../components/navigation/desktop/SidenavContext";
import { SidenavContainer } from "../components/navigation/desktop/SidenavContainer";
import { Sidenav } from "../components/navigation/desktop/Sidenav";
import { AiOutlineDashboard } from "react-icons/ai";
import { FaStairs } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export const HomePage = () => {
  const { t } = useTranslation();
  const labelDashboard = t("text_dashboard");
  const labelFloors = t("text_floors");
  const sideNavItems = [
    { icon: AiOutlineDashboard, label: labelDashboard, to: "dashboard" },
    { icon: FaStairs, label: labelFloors, to: "floors" },
  ];

  return (
    <SidenavProvider>
      <NavBar2 />
      <SidenavContainer sidenav={<Sidenav navItems={sideNavItems} />}>
        <main>
          <Outlet />
        </main>
      </SidenavContainer>
    </SidenavProvider>
  );
};

//  <Grid templateColumns="repeat(6,1fr)" bg="gray.50">
//    <GridItem as="aside" colSpan={{base: 0, lg:0, xl: 1}} minHeight={{lg: "100vh"}} bg="orange.50" p="30px">
//      <SideNav sideNavItems={sideNavItems}/>
//    </GridItem>
//
//    <GridItem as="main" colSpan={{base:6, lg: 5}}>
//    <NavBar/>
//    <Outlet/>
//    </GridItem>
//  </Grid>

//);
