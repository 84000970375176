import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { forgotPassword } from "../authService.ts";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Box,
  Avatar,
  FormControl,
  Text,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState(location.state?.email || "");

  const handleSubmit = async () => {
    //  e.preventDefault();
    try {
      await forgotPassword(email);
      alert("An email containing verification code has been sent to you");
      navigate("/resetpassword");
    } catch (error) {
      alert(`Failed to send verification code: ${error}`);
    }
  };
  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        width="340px"
        minHeight="500px"
        pt="50px"
        pb="40px"
        justifyContent="center"
        alignItems="center"
        backgroundColor="whiteAlpha.900"
      >
        <Avatar bg="orange.500" />
        <Text fontSize="lg" color="orange.400">
          Forgot Password?
        </Text>
        <Text fontSize="sm" color="orange.400">
          Please enter email for your account.
        </Text>

        <Box mt="10" minW={{ base: "95%" }}>
          <form>
            <Stack
              spacing={4}
              pt="1rem"
              pb="2rem"
              px="1rem"
              backgroundColor="whiteAlpha.900"
            >
              <FormControl>
                <InputGroup>
                  <Input
                    onChange={handleOnChange}
                    type="email"
                    placeholder="email address"
                  />
                </InputGroup>
              </FormControl>

              <Button
                borderRadius={0}
                variant="solid"
                colorScheme="orange"
                width="full"
                onClick={handleSubmit}
              >
                Reset Password
              </Button>
              <Box pr="10px" display="flex" justifyContent="flex-end">
                <Text fontSize="12px">Already have an account? </Text>
                <Text fontSize="12px">
                  <ChakraLink as={ReactRouterLink} to="/login">
                    Sign-in
                  </ChakraLink>
                </Text>
              </Box>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default ForgotPasswordPage;
