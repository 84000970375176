import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmSignUp } from "../authService.ts";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Box,
  Avatar,
  FormControl,
  Text,
  Alert,
  AlertDescription,
  Spacer,
  CloseButton,
} from "@chakra-ui/react";

const ConfirmUserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState(location.state?.email || "");
  const [confirmationCode, setConfirmationCode] = useState("");

  const onCloseAlert = () => setErrorMessage(null);
  const showAlert = () => {
    return (
      <Alert status="error">
        <Box>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Box>
        <Spacer />
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          onClick={onCloseAlert}
        />
      </Alert>
    );
  };

  const handleSubmit = async () => {
    //  e.preventDefault();
    try {
      await confirmSignUp(email, confirmationCode);
      // alert("Account confirmed successfully!\nSign in on next page.");
      navigate("/login");
    } catch (error) {
      setErrorMessage("Failed to confirm account");
      //alert(`Failed to confirm account: ${error}`);
    }
  };
  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        width="340px"
        minHeight="500px"
        pt="50px"
        pb="40px"
        justifyContent="center"
        alignItems="center"
        backgroundColor="whiteAlpha.900"
      >
        <Avatar bg="orange.500" />
        <Text fontSize="lg" color="orange.400">
          Confirm Account
        </Text>
        <Box mt="10" minW={{ base: "95%" }}>
          <form>
            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900">
              {errorMessage != null ? showAlert() : ""}
              <FormControl>
                <InputGroup>
                  <Input
                    onChange={handleOnChange}
                    type="email"
                    placeholder="email address"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <Input
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    type="text"
                    placeholder="verification code"
                    value={confirmationCode}
                  />
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={0}
                variant="solid"
                colorScheme="orange"
                width="full"
                onClick={handleSubmit}
              >
                Confirm Account
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
//type="submit"
/*  <div className="loginForm">
    <h2>Confirm Account</h2>
    <form onSubmit={handleSubmit}>
      <div>
        <input
          className="inputText"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
      </div>
      <div>
        <input
          className="inputText"
          type="text"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          placeholder="Confirmation Code"
          required />
      </div>
      <button type="submit">Confirm Account</button>
    </form>
  </div> */

export default ConfirmUserPage;
