import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { FallDetectDevice } from "../components/FallDetectDevice.jsx";
import React, { useState, useContext, useEffect } from "react";
import { SignalRContext } from "./SignalRContext";

export const DisabledToiletsPage = () => {
  const { message } = useContext(SignalRContext);
  const [deviceList, setDeviceList] = useState([]);

  const disabledToilets = deviceList.filter((e) => {
    if (e.deviceType === 3) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    // Trigger the loader to fetch data
    if (message.flag === true) {
      //   console.log("flag = " + message.flag);
      setDeviceList(message.devices);
    }
    //   console.log("SignalR message received in Child2:", message);
    // Fetch new data or update the content here
  }, [message]);

  return (
    <Box mt="20px" ml="20px" mr="20px">
      <Text mb="20px" ml="5px" fontSize="sm"></Text>
      <SimpleGrid columns={[1, 1, 2, 2, 3, 3, 4]} spacing="10px">
        {disabledToilets.map((e) => (
          <FallDetectDevice key={e.deviceId} device={e} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
