import React from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LogoutButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    sessionStorage.setItem("accessToken", "");
    navigate("/login");
  };
  return (
    <Button colorScheme="orange" onClick={handleLogout}>
      {t("text_logout")}
    </Button>
  );
}
