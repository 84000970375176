import queryString from 'query-string';
import { signIn, signUp } from "../authService.ts";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AppAuth = () => {
    const location = useLocation();
    useEffect(() => {
        const authenticate = async () => {
            // Parse the URL parameters
            const params = queryString.parse(location.search);
            const { email, password } = params;

            if (email && password) {
                try {
                    const session = await signIn(email, password);
                    console.log("Sign in successful", session);
                    if (session && typeof session.AccessToken !== "undefined") {
                        sessionStorage.setItem("accessToken", session.AccessToken);
                        if (sessionStorage.getItem("accessToken")) {
                            window.location.href = "/floors";
                        } else {
                            console.error("Session token was not set properly.");
                        }
                    } else {
                        console.error("SignIn session or AccessToken is undefined.");
                    }
                } catch (error) {
                    //alert(`Sign in failed: ${error}`);
                    console.error("SignIn failed.");
                }
            }
        };
        authenticate();
    }, [location.search]);

};

export default AppAuth;