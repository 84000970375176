import { Box, SimpleGrid, Text, Select, HStack } from "@chakra-ui/react";
import { Device } from "../components/Device.jsx";
import { useLoaderData, redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState, useContext, useEffect } from "react";
import { SignalRContext } from "./SignalRContext";
import { getAnalytics, logEvent } from "firebase/analytics";

function getCurrentFloorDevices(devices, floor, type) {
  return devices.filter((device) => device.floor === floor);
}
//&& device.deviceType === type
export const Floors = () => {
  const { message } = useContext(SignalRContext);
  const { t } = useTranslation();
  const devicesFromApi = useLoaderData();
  const [devices, setDevices] = useState([]);

  const [floors, setFloors] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState("P");

  useEffect(() => {
    fetch(domain + "/buildinginfo/getAllFloors")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setFloors(data);
      });
  }, []);

  let deviceList = [];
  if (message.flag === false) {
    deviceList = devicesFromApi;
  } else {
    deviceList = devices;
  }

  const handleChange = (e) => {
    setSelectedFloor(e.target.value);
  };
  const currentFloorDevices = getCurrentFloorDevices(deviceList, selectedFloor);
  useEffect(() => {
    // Trigger the loader to fetch data
    if (message.flag === true) {
      setDevices(message.devices);
    }
    //   console.log("SignalR message received in Child2:", message);
    // Fetch new data or update the content here
  }, [message]);
  //// 1,2,3,4,5
  return (
    <>
      <HStack pr="10px" pt="5px" pb="5px" justify="right">
        <Box direction="flex">
          <Select
            size="sm"
            placeholder={t("text_select_floor")}
            onChange={handleChange}
          >
            {floors.map((floor) => (
              <option key={floor.buildingInfoId} value={floor.floor}>
                {floor.description}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>
      <Box mt="20px" ml="20px" mr="20px">
        <Text mb="20px" ml="5px" fontSize="sm"></Text>
        <SimpleGrid columns={[2, 2, 2, 2, 3, 3, 4]} spacing="20px">
          {currentFloorDevices.map((e) => (
            <Device key={e.deviceId} device={e} />
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
};

//</Box>
//                <Box mt="20px" ml='20px' mr='20px'>
//                <Text mb='20px' ml='5px' fontSize='sm'>Dryer Machines</Text>
//                <SimpleGrid columns={[1,2,3,4,4]} spacing='20px'>
//                {currentFloorDevices.map((e)=>
//                    <DryerStatusBox device = {e }  />)};
//
//               </SimpleGrid>
//           </Box>
const domain = process.env.REACT_APP_API_SERVER_URL;
const accessToken = sessionStorage.getItem("accessToken");

const requestOptions = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
};

const getDeviceOnAllFloors = domain + "/Devices/getDevicesOnAllFloors";
export const floorDataLoader = async () => {
  const analytics = getAnalytics();

  const res = await fetch(getDeviceOnAllFloors, requestOptions);
  if (res.ok) {
    const result = await res.json();
    return result;
  } else {
    console.log("Fetch error %s return %d", getDeviceOnAllFloors, res.status);
    logEvent(analytics, "error", { status_code: res.status });
    if (res.status === 401) {
      return redirect("/login");
    } else {
      throw new Response("", { status: res.status });
    }
  }
};
