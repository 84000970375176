// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { 
  CognitoIdentityProviderClient, 
  InitiateAuthCommand, 
  SignUpCommand, 
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand
} from "@aws-sdk/client-cognito-identity-provider";
import config from "./config.json";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const signIn = async (username: string, password: string) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
      const command = new InitiateAuthCommand(params);
      const { AuthenticationResult } = await cognitoClient.send(command);
      if (AuthenticationResult) {
          sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
          sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
          sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
          return AuthenticationResult;
      }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const signUp = async (email: string, password: string) => {
  const params = {
    ClientId: config.clientId,
    Username: email,
    Password: password,
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
    ],
  };
  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    console.log("Sign up success: ", response);
    return response;
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

export const confirmSignUp = async (username: string, code: string) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
    ConfirmationCode: code,
  };
  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    console.log("User confirmed successfully");
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};

export const forgotPassword = async (username: string) => {

  const params = { // ForgotPasswordRequest
    ClientId: config.clientId, // required
 // SecretHash: "STRING_VALUE",
 // UserContextData: { // UserContextDataType
 //   IpAddress: "STRING_VALUE",
//    EncodedData: "STRING_VALUE",
//  },
    Username: username, // required
//  AnalyticsMetadata: { // AnalyticsMetadataType
//    AnalyticsEndpointId: "STRING_VALUE",
//  },
//  ClientMetadata: { // ClientMetadataType
//    "<keys>": "STRING_VALUE",
//  },
  };
  try {
    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    console.log("response %s",response);
    console.log("Forgotpassword sent successfully");
  } catch (error) {
    console.error("Error requesting forgot password: ", error);
    throw error;
  }
// { // ForgotPasswordResponse
//   CodeDeliveryDetails: { // CodeDeliveryDetailsType
//     Destination: "STRING_VALUE",
//     DeliveryMedium: "SMS" || "EMAIL",
//     AttributeName: "STRING_VALUE",
//   },
// };
}
export const resetPassword = async (username: string,password: string,code: string ) => {
  const input = { // ConfirmForgotPasswordRequest
    ClientId: config.clientId, // required
 // SecretHash: "STRING_VALUE",
    Username: username, // required
    ConfirmationCode: code, // required
    Password: password, // required
  //AnalyticsMetadata: { // AnalyticsMetadataType
  //  AnalyticsEndpointId: "STRING_VALUE",
  //},
  //UserContextData: { // UserContextDataType
  //  IpAddress: "STRING_VALUE",
  //  EncodedData: "STRING_VALUE",
  //},
  //ClientMetadata: { // ClientMetadataType
  //  "<keys>": "STRING_VALUE",
  //},
  };
  try {
    const command = new ConfirmForgotPasswordCommand(input);
    const response = await cognitoClient.send(command);
    console.log("response %s",response);
    console.log("ConfirmForgotpassword sent successfully");
  } catch (error){
    console.error("Error sending confirm forgot password: ", error);
    throw error;
  }
}
