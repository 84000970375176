import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { HomePage } from "./pages/HomePage.jsx";
import { NotFoundPage } from "./pages/NotFound.jsx";
import { Dashboard } from "./pages/Dashboard.jsx";
import { Floors } from "./pages/Floors.jsx";
import { sensorDataLoader } from "./pages/Dashboard.jsx";
import { floorDataLoader } from "./pages/Floors.jsx";

import { ChakraProvider } from "@chakra-ui/react";
import { IndoorAirPage } from "./pages/InDoorAirPage.jsx";
import { DisabledToiletsPage } from "./pages/DisabledToiletsPage.jsx";
import { RootTopExitsPage } from "./pages/RootTopExitsPage.jsx";
import { WaterLeaksPage } from "./pages/WaterLeaksPage.jsx";
import { MotionDetectPage } from "./pages/MotionDetectPage.jsx";
import { WashingMachinePage } from "./pages/WashingMachinePage.jsx";
import { DryerPage } from "./pages/DryerPage.jsx";
import AppAuth from "./pages/appAuth.jsx";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";

import LoginPage from "./pages/loginPage.jsx";
import ConfirmUserPage from "./pages/confirmUserPage.jsx";
import ForgotPasswordPage from "./pages/forgotPasswordPage.jsx";
import ResetPasswordPage from "./pages/resetPasswordPage.jsx";
//import TestPage from "./pages/appAuth.jsx";

// Import the functions you need from the SDKs you need
import { SignalRProvider } from "./pages/SignalRContext.jsx";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpHOvH__5GcPjzmnwhtPJG_7WE9v50Ads",
  authDomain: "plkoasis.firebaseapp.com",
  projectId: "plkoasis",
  storageBucket: "plkoasis.appspot.com",
  messagingSenderId: "625319396612",
  appId: "1:625319396612:web:fba6a63b0b2035067dbc51",
  measurementId: "G-QNY1YDMXG4",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const container = document.getElementById("root");
const root = createRoot(container);

//loader= {sensorDataLoader} loader= {sensorDataLoader}
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <HomePage />
      </PrivateRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "floors",
        element: (
          <PrivateRoute>
            <Floors />
          </PrivateRoute>
        ),
        loader: floorDataLoader,
        errorElement: <NotFoundPage />,
      },
      {
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
        index: true,
        loader: sensorDataLoader,
        errorElement: <NotFoundPage />,
      },
      {
        path: "dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
        loader: sensorDataLoader,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/dashboard/indoorair",
        element: (
          <PrivateRoute>
            <IndoorAirPage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },

      {
        path: "/dashboard/disabledtoilets",
        element: (
          <PrivateRoute>
            <DisabledToiletsPage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/dashboard/rooftopexits",
        element: (
          <PrivateRoute>
            <RootTopExitsPage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/dashboard/waterleakages",
        element: (
          <PrivateRoute>
            <WaterLeaksPage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/dashboard/twentyfifthfloormotion",
        element: (
          <PrivateRoute>
            <MotionDetectPage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/dashboard/washingmachine",
        element: (
          <PrivateRoute>
            <WashingMachinePage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/dashboard/dryer",
        element: (
          <PrivateRoute>
            <DryerPage />
          </PrivateRoute>
        ),
        errorElement: <NotFoundPage />,
      },

      // },
    ],
  },

  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/confirm",
    element: <ConfirmUserPage />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/resetpassword",
    element: <ResetPasswordPage />,
    },
    {
        path: "/auth",
        element: <AppAuth />,
    },
]);

function PrivateRoute({ children }) {
  const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    return !!accessToken;
  };
  return isAuthenticated() ? children : <Navigate to="/login" />;
}

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <I18nextProvider i18n={i18n}>
        <SignalRProvider>
          <RouterProvider router={router} />
        </SignalRProvider>
      </I18nextProvider>
    </ChakraProvider>
  </React.StrictMode>
);
