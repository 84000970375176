import React from "react";
import { Heading } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const error = useRouteError();
  console.error(error);
  return (
    <div>
      <p>{t("text_error")}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
